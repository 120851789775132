import React from "react";
import cx from "classnames";

import { ModuleWrapper } from "../moduleWrapper";
import { PortableTextBlock } from "@components/sanity/portableTextBlock";

const TextContent = ({ config, text, centerText, layout }) => {
  return (
    <ModuleWrapper {...config}>
      <div className="grid grid-cols-14">
        <div
          className={cx("col-start-2 col-end-14", {
            "text-center md:col-start-3 md:col-end-13 lg:col-start-4 lg:col-end-12":
              centerText,
            "md:col-end-11": !centerText && layout === "left",
            "md:col-end-7": !centerText && layout === "drop-left",
            "md:col-start-8": !centerText && layout === "right",
            "md:col-start-9": !centerText && layout === "drop-right",
            "md:col-start-9": !centerText && layout === "drop-right",
          })}
        >
          {text && <PortableTextBlock text={text} />}
        </div>
      </div>
    </ModuleWrapper>
  );
};

export default TextContent;
